import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import HistoryProps from "../../core/interfaces/HistoryProps";
import Navbar from "../../components/navbar/Navbar";
import AuctionDetailForm from "../../components/AuctionDetailForm";
import AuctionItemsList from "../../components/AuctionItemsList";
import { Auction, AuctionItem } from "../../core/interfaces/Auction";
import AuctionService from "../../core/AuctionService";
import { isSuccess } from "../../core/interfaces/Result";

async function loadAuctionItems(auctionId: number, setAuctionItems: React.Dispatch<AuctionItem[]>) {
    const service = new AuctionService();
    
    const result = await service.getAuctionItems(auctionId);
    if (isSuccess(result)) {
        setAuctionItems(result);
    }
}

async function loadAuction(auctionId: number, setAuction: React.Dispatch<Auction>) {
    const service = new AuctionService();

    const result = await service.getAuction(auctionId);
    if (isSuccess(result)) {
        setAuction(result);
    }
}

const AuctionPage: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    const pathParts = history.location.pathname.split("/");

    const eventId = Number(pathParts[2]);
    const auctionId = Number(pathParts[4]);

    const [auction, setAuction] = useState<Auction>();
    const [auctionItems, setAuctionItems] = useState<AuctionItem[]>([]);
    
    const loadData = async () => {
        await loadAuction(Number(auctionId), setAuction);
        await loadAuctionItems(Number(auctionId), setAuctionItems);
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        //loadData();
        loadAuction(Number(auctionId), setAuction);
        loadAuctionItems(Number(auctionId), setAuctionItems);
    }, [auctionId]);

    return (
        <div>
            <Navbar history={history} />
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <AuctionDetailForm auction={auction} />
                    </div>
                </div>
                <div className="row justify-content-center mt-4 mb-4">
                    <div className="col-lg-6">
                        <AuctionItemsList eventId={eventId} auctionId={auctionId} auctionItems={auctionItems} history={history} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(AuctionPage);
