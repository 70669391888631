import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from "react-router-dom";

import Authenticator from './core/Authenticator';

import LoginPage from './pages/login/LoginPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import NewAuctionItemPage from "./pages/auction/NewAuctionItemPage";
import NewPasswordResetPage from './pages/password-reset/NewPasswordResetPage';
import NewPasswordPage from './pages/password-reset/NewPasswordPage';
import AuctionPage from './pages/auction/AuctionPage';
import EditAuctionItemPage from './pages/auction/EditAuctionItemPage';
import AuctionItemPage from './pages/auction/AuctionItemPage';
import { StripeProvider } from 'react-stripe-elements';

interface RouteProps {
    path: string;
    component: typeof Component;
}

const ProtectedRoute = ({component: Component, ...rest}: RouteProps) => {
    const authenticator = new Authenticator();

    return (
        <Route {...rest} 
            render={props => !authenticator.isAuthenticated() ? 
                ( <Redirect to={{ pathname: "/login" }} /> ) : 
                ( <Component {...props} />)} />
    )
}

const App: React.FC = () => {
    const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

    return (
        <StripeProvider apiKey={apiKey!}>
            <Router>
                <Switch>
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <Route path="/password/reset">
                        <NewPasswordResetPage />
                    </Route>
                    <Route path="/password/new">
                        <NewPasswordPage />
                    </Route>
                    <ProtectedRoute path="/auctions/:auction/items/:item/edit" component={EditAuctionItemPage} />
                    <ProtectedRoute path="/auctions/:auction/items/:item" component={AuctionItemPage} />
                    <ProtectedRoute path="/events/:event/auctions/:auction/items/new" component={NewAuctionItemPage} />
                    <ProtectedRoute path="/events/:event/auctions/:auction" component={AuctionPage} />
                    <ProtectedRoute path="/" component={DashboardPage} />
                </Switch>
            </Router>
        </StripeProvider>
    );
}

export default App;
