import React from "react";

import { Event } from "../../core/interfaces/Event";

import "./toolbar.scss";

interface ToolbarProps {
    events: Event[];
    setSelectedEvent: React.Dispatch<Event>;
}

const Toolbar: React.FC<ToolbarProps> = ({events, setSelectedEvent}: ToolbarProps) => {
    const onEventSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = Number(event.target.value);
        events.forEach(event => {
            if (event.id === id) {
                setSelectedEvent(event);
            }
        });
    };

    return (
        <div className="toolbar-bg">
            <div className="row">
                <div className="col-lg-6">
                    <select className="form-control" onChange={onEventSelected}>
                    {events.map((event) =>
                        <option key={event.id} value={event.id}>{event.name}</option>
                    )}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Toolbar;
