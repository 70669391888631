import React from "react";
import { AuctionItem } from "../core/interfaces/Auction";

interface AuctionItemListItemProps {
    auctionItem: AuctionItem;
}

const AuctionItemListItem: React.FC<AuctionItemListItemProps> = ({auctionItem}: AuctionItemListItemProps) => {
    return (
        <a href={"/auctions/" + auctionItem.auction.id + "/items/" + auctionItem.id}
            className="list-group-item list-group-item-action flex-column align-items-start">
            <h5 className="font-weight-light text-dark">{auctionItem.title}</h5>
            {auctionItem.isDraft &&
            <label className="badge badge-light">Draft</label>
            }
        </a>
    );
}

export default AuctionItemListItem;
