import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HistoryProps from "../../core/interfaces/HistoryProps";

import galaLogo from "../../images/gala_logo.svg";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Authenticator from "../../core/Authenticator";
import { isError } from "util";

async function changePassword(newPassword: string, confirmationPassword: string, token: string, history: History) {
    resetError();
    showLoadingProgress();

    if (newPassword === undefined || newPassword === "") {
        hideLoadingProgress();
        showError("A new password is required.");
        return;
    }

    if (confirmationPassword === undefined || confirmationPassword === "") {
        hideLoadingProgress();
        showError("Please confirm your new password.");
        return;
    }

    if (newPassword !== confirmationPassword) {
        hideLoadingProgress();
        showError("The passwords entered do not match.");
        return;
    }

    const authenticator = new Authenticator();
    
    const result = await authenticator.changePassword(newPassword, token);
    if (isError(result)) {
        hideLoadingProgress();
        showError(result.message);
        return;
    }

    history.replace("/login");
}

function showLoadingProgress() {
    $("#change-password-button").hide();
    $("#password-change-loading").show();
}

function hideLoadingProgress() {
    $("#password-change-loading").hide();
    $("#change-password-button").show();
}

function showError(message: string) {
    $("#password-change-alert-message").text(message);
    $("#password-change-alert").show();
}

function resetError() {
    $("#password-change-alert").hide();
}

const NewPasswordPage: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    const params = queryString.parse(history.location.search);

    const [newPassword, setNewPassword] = useState();
    const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value);

    const [confirmPassword, setConfirmPassword] = useState();
    const onConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);

    const onSubmitChangePassword = (event: React.FormEvent) => {
        event.preventDefault();

        const token = params["token"] as string;
        changePassword(newPassword, confirmPassword, token, history);
    };

    return (
        <div className="container">
            <div className="row mt-4 justify-content-center align-items-center">
                <div className="col-lg-4">
                    <img src={galaLogo} alt="St. Martin de Porres School Gala & Auction" className="col-12" />
                    <div className="card mt-4">
                        <div className="card-body">
                            <div id="password-change-alert" className="alert alert-danger collapse">
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /><span id="password-change-alert-message"></span>
                            </div>
                            <form onSubmit={onSubmitChangePassword}>
                                <input id="password-field" type="password" className="form-control mt-2" placeholder="New Password" onChange={onNewPasswordChange} />
                                <input id="password-confirmation-field" type="password" className="form-control mt-2" placeholder="Confirm Password" onChange={onConfirmPasswordChange} />
                                <div className="mt-4 text-center">
                                    <div className="d-flex justify-content-center">
                                        <div id="password-change-loading" className="spinner-border text-primary collapse" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <button id="change-password-button" type="submit" className="btn btn-primary col-12">Change Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewPasswordPage);
