import React from "react";
import { Auction } from "../core/interfaces/Auction";

interface AuctionListItemProps {
    auction: Auction;
}

const AuctionListItem: React.FC<AuctionListItemProps> = ({auction}: AuctionListItemProps) => {
    return (
        <a href={"/events/" + auction.eventId + "/auctions/" + auction.id} 
                className="list-group-item list-group-item-action flex-column align-items-start">
            <h5 className="font-weight-light text-dark">{auction.auctionType}</h5>
        </a>
    );
}

export default AuctionListItem;
