import { Attendance } from "./interfaces/Attendance";
import { Ticket } from "./interfaces/Ticket";
import { Result } from "./interfaces/Result";
import { ServiceError } from "./interfaces/Error";

import Authenticator from "./Authenticator";
import { Event } from "./interfaces/Event";

const axios = require("axios").default;

export default class TicketService {
    async getTickets(event: Event): Promise<Result<Ticket[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/admin/events/${event.id}/tickets`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });
            if (response.status === 200) {
                const tickets: Ticket[] = response.data;
                return tickets;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }

    async getAttendance(event: Event): Promise<Result<Attendance[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/admin/events/${event.id}/attendance`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const attendance: Attendance[] = response.data;
                return attendance;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }

    async addAttendee(name: string, userId: number, bidNumber: number): Promise<Result<Attendance>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/admin/attendance`, {
                "name": name,
                "userId": userId,
                "bidNumber": bidNumber 
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const attendance: Attendance = response.data;
                return attendance;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }
}
