import { Event } from "./interfaces/Event";
import { Result } from "./interfaces/Result";
import { ServiceError } from "./interfaces/Error";

const axios = require("axios").default;

export default class EventService {
    async getEvents(): Promise<Result<Event[]>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/events`);
            if (response.status === 200) {
                const events: Event[] = response.data;
                return events;
            }

            return new ServiceError(response.status, "Unable to fetch events list.");
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }
}
