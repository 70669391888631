import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import Showdown from "showdown";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "../../components/navbar/Navbar";
import GalleryCollection from "../../components/GalleryCollection";

import $ from "jquery";

import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import AuctionService from "../../core/AuctionService";
import { isSuccess } from "../../core/interfaces/Result";
import { Auction, AuctionItem, Bid, EventAuction } from "../../core/interfaces/Auction";
import { GalleryItem } from "../../core/interfaces/GalleryItem";
import { Transaction } from "../../core/interfaces/Transaction";
import CreateAuctionItemPaymentModal from "./CreateAuctionItemPaymentModal";
import { Elements } from "react-stripe-elements";

function showModal(event: React.MouseEvent) {
    event.preventDefault();
    $("#delete-modal").modal("show");
}

function dismissModal(event: React.MouseEvent) {
    event.preventDefault();
    $("#delete-modal").modal("hide");
}

function showPaymentModal(event: React.MouseEvent) {
    event.preventDefault();
    $("#create-payment-modal").modal("show");
}

function dismissPaymentModal() {
    $("#create-payment-modal").modal("hide");
}

async function loadAuctionItem(history: History, auctionId: number, auctionItemId: number, setAuctionItem: Function, setImages: React.Dispatch<GalleryItem[]>) {
    const service = new AuctionService();

    const result = await service.getAuctionItem(auctionId, auctionItemId);
    if (isSuccess(result)) {
        setAuctionItem(result);

        const galleryResult = await service.getImagesForAuctionItem(auctionItemId);
        if (isSuccess(galleryResult)) {
            setImages(galleryResult);
        }
    } else {
        history.goBack();
    }
}

async function loadBids(auctionItemId: number, setBids: React.Dispatch<Bid[]>) {
    const service = new AuctionService();
    
    const bids = await service.getBids(auctionItemId);
    if (isSuccess(bids)) {
        setBids(bids);
    }
}

async function loadTransactions(auctionItemId: number, setTransactions: React.Dispatch<Transaction[]>) {
    const service = new AuctionService();

    const transactions = await service.getAuctionItemTransactions(auctionItemId);
    if (isSuccess(transactions)) {
        if (transactions != null) {
            setTransactions(transactions);
        }
    }
}

async function loadAuctionEvents(setAuctionEvents: React.Dispatch<EventAuction[]>) {
    const service = new AuctionService();
    const res = await service.getAuctionEvents();

    if (isSuccess(res)) {
        setAuctionEvents(res);
    }
}

function showSuccess(message: string) {
    $("#form-alert-message").html(message);
    $("#form-alert").show();
}

function showError(message: string) {
    $("#error-alert-message").text(message);
    $("#error-alert").show();
}

interface AuctionItemPageProps {
    history: History;
}

const AuctionItemPage: React.FC<AuctionItemPageProps> = ({history}: AuctionItemPageProps) => {
    const pathParts = history.location.pathname.split("/");
    const auctionId = pathParts[2];
    const auctionItemId = pathParts[4];

    const [title, setTitle] = useState();
    const [content, setContent] = useState();

    const [bids, setBids] = useState<Bid[]>([]);
    const [auctionEvents, setAuctionEvents] = useState<EventAuction[]>([]);
    const [item, setItem] = useState<AuctionItem>();

    const [transactions, setTransactions] = useState<Transaction[]>();

    const [images, setImages] = useState<GalleryItem[]>([]);
    const setAuctionItem = (item: AuctionItem) => {
        setItem(item);
        setTitle(item.title);
        
        const mdConverter = new Showdown.Converter({tables: true, simplifiedAutoLink: true, tasklists: false, strikethrough: true});
        setContent({__html: mdConverter.makeHtml(item.pageContent)});
    };

    const refreshImages = async () => {
        await loadAuctionItem(history, Number(auctionId), Number(auctionItemId), setAuctionItem, setImages);
    };

    const copyAuctionItem = async () => {
        const auctionId = $("#auction-events").prop('value');

        const service = new AuctionService();
        const res = await service.copyAuctionItem(parseInt(auctionItemId), parseInt(auctionId));

        if (isSuccess(res)) {
            showSuccess("Item successfully copied: <a href='" + res + "'>New Item Page</a>");
        } else {
            showError("There was an error copying the auction item.");
        }
    };

    const deleteAuctionItem = async () => {
        const service = new AuctionService();
        const res = await service.deleteAuctionItem(parseInt(auctionItemId));

        if (isSuccess(res)) {
            history.goBack();
        }
    };

    const onPaymentComplete = async () => {
        loadTransactions(Number(auctionItemId), setTransactions);
        dismissPaymentModal();
    };

    useEffect(() => {
        loadAuctionItem(history, Number(auctionId), Number(auctionItemId), setAuctionItem, setImages);
        loadBids(Number(auctionItemId), setBids);
        loadAuctionEvents(setAuctionEvents);
        loadTransactions(Number(auctionItemId), setTransactions);
    }, [setItem]);

    return (
        <div>
            <Navbar history={history} />
            <div className="container mt-4 mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-8 mt-4">
                        <div className="card">
                            <div className="card-header d-flex w-100">
                                <p className="my-auto">{item?.title}</p>
                                <div className="ml-auto">
                                    <button type="button" className="btn btn-outline-danger" onClick={(e) => { showModal(e); }}>Delete</button>
                                    <a href={"/auctions/" + auctionId + "/items/" + auctionItemId + "/edit"} className="btn btn-outline-primary ml-3">Edit</a>
                                </div>
                            </div>
                            <div className="card-body">
                                <p dangerouslySetInnerHTML={content}></p>
                            </div>
                        </div>
                        <div className='card mt-4'>
                            <div className='card-header'>
                                Copy This Item
                            </div>
                            <div className='card-body'>
                                <label htmlFor='auction-events' className='form-label text-muted text-small'>Choose the auction to copy this item to:</label>
                                <select id='auction-events' name='auction-events' className='form-control w-75'>
                                    {auctionEvents.map((event: EventAuction) =>
                                        <>
                                        {event.auctions.map((auction: Auction) =>
                                        <option value={auction.id}>{event.event.name} {auction.auctionType}</option>
                                        )}
                                        </>
                                    )}
                                </select>
                                <button type='button' className='btn btn-outline-primary mt-3' onClick={(e) => { copyAuctionItem(); }}>Copy Item</button>
                                <div id="form-alert" className="alert alert-success collapse mt-3">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /><span id="form-alert-message"></span>
                                </div>
                                <div id="error-alert" className="alert alert-danger collapse mt-3">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /><span id="error-alert-message"></span>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">Bids</div>
                            <div className="card-body m-0 p-0">
                                <ul className="list-group list-group-flush">
                                    {bids.length === 0 &&
                                    <li className="list-group-item flex-column mx-auto empty-list">
                                        <p className="text-muted mt-3">No bids</p>
                                    </li>
                                    }
                                    {bids.map((bid) =>
                                    <li className="list-group-item flex-column align-items-start">
                                        <div className="d-flex w-100">
                                            <p>{bid.user.firstName} {bid.user.lastName} ({bid.user.email})</p>
                                            <p className="ml-auto">
                                                <span className="badge badge-primary">${bid.bidAmount}.00</span>
                                            </p>
                                        </div>
                                        <p className="mt-n3 text-muted">
                                            {moment(bid.createdAt * 1000).format("MMMM Do, YYYY hh:MM a")}
                                        </p>
                                    </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className='card mt-4'>
                            <div className='card-header d-flex w-100'>
                            <p className="my-auto">Payments</p>
                            <div className="ml-auto">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={(e) => { showPaymentModal(e); }}>Create Payment</button>
                            </div>
                            </div>
                            <div className='card-body m-0 p-0'>
                                <ul className='list-group list-group-flush'>
                                {transactions?.map((transaction) =>
                                    <li key={transaction.id} className='list-group-item flex-column align-items-start'>
                                        <div className='d-flex w-100'>
                                            <p>{transaction.user.firstName} {transaction.user.lastName} ({transaction.user.email})</p>
                                            <p className="ml-auto">
                                                <span className="badge badge-primary">${transaction.amount / 100}.00</span>
                                            </p>
                                        </div>
                                        <p className='mt-n3 text-muted'>
                                            Payment ID: {transaction.processorId}
                                        </p>
                                    </li>
                                )}
                                {(transactions == null || transactions.length === 0) &&
                                <li className="list-group-item flex-column mx-auto empty-list">
                                    <p className="text-muted mt-3">No payments</p>
                                </li>
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4">
                        <div className="card">
                            <div className="card-header">Images</div>
                            <div className="card-body m-0 p-0">
                                <GalleryCollection images={images} auctionItemId={parseInt(auctionItemId)} refreshImages={refreshImages} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="delete-modal" className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">Delete Auction Item</div>
                        <div className="modal-body">
                            Are you sure you want to delete this auction item?
                        </div>
                        <div className="modal-footer">
                            <div className="d-flex w-100">
                                <div id="image-upload-buttons" className="ml-auto">
                                    <button className="btn btn-sm btn-outline-primary" onClick={e => dismissModal(e)}>Cancel</button>
                                    <button className="btn btn-sm btn-outline-danger ml-4" type="button" onClick={(e) => { deleteAuctionItem(); }}>Delete</button>
                                </div>
                                <div id="image-upload-progress" className="ml-auto collapse">
                                    <div id="upload-loading" className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Elements>
                <CreateAuctionItemPaymentModal item={item} onPaymentComplete={onPaymentComplete} />
            </Elements>
        </div>
    );
};

export default withRouter(AuctionItemPage);
