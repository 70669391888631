import React from 'react';
import $ from "jquery";
import { CardElement, injectStripe } from 'react-stripe-elements';
import { User } from '../../core/interfaces/User';
import Authenticator from '../../core/Authenticator';
import { isSuccess } from '../../core/interfaces/Result';
import { AuctionItem, AuctionItemCharge } from '../../core/interfaces/Auction';
import AuctionService from '../../core/AuctionService';

function dismissModal(event: React.MouseEvent) {
    event.preventDefault();
    $("#create-payment-modal").modal("hide");
}

interface State {
    users: User[];
    selectedUser: number;
    itemAmount: number;
    item: AuctionItem | null;
    firstName: string;
    lastName: string;
    email: string;
}

class CreateAuctionItemPaymentModal extends React.Component<any, State> {
    constructor(props) {
        super(props);
        console.log(props);

        let itemAmount = 0;
        if (props.item != null) {
            itemAmount = props.item.winningPrice;
        }

        this.state = { users: [], selectedUser: -1, itemAmount: itemAmount, item: null, firstName: '', lastName: '', email: ''};
    }

    onFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        $("#payment-button").hide();
        $("#payment-dismiss-button").hide();
        $("#payment-loading").show();

        const { token, error } = await this.props.stripe.createToken({
            "name": this.state.firstName + " " + this.state.lastName
        });

        if (error != null) {
            $("#payment-loading").hide();
            $("#payment-button").show();
            $("#payment-dismiss-button").show();

            $("#payment-alert").text(error.message);
            $("#payment-alert").show();
            return;
        }

        const service = new AuctionService();
        if (this.state.item != null) {
            const payload: AuctionItemCharge = {
                item_id: this.state.item.id,
                amount: this.state.itemAmount,
                card_id: token.id,
                card_type: token.card.brand,
                card_four: token.card.last4,
                card_exp_month: token.card.exp_month.toString(),
                card_exp_year: token.card.exp_year.toString(),
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                user_id: this.state.selectedUser
            };

            const res = await service.chargeForAuctionItem(payload);
            if (isSuccess(res)) {
                if (this.props.onPaymentComplete != null) {
                    this.props.onPaymentComplete();
                }

                $("#payment-loading").hide();
                $("#payment-button").show();
                $("#payment-dismiss-button").show();
            } else {
                $("#payment-loading").hide();
                $("#payment-button").show();
                $("#payment-dismiss-button").show();

                $("#payment-alert").text(res.message);
                $("#payment-alert").show();
            }
        }
    }

    async componentDidMount() {
        const authenticator = new Authenticator();
        const users = await authenticator.getUsers();

        if (isSuccess(users)) {
            this.setState((prevState, props) => ({
                users: users,
                selectedUser: prevState.selectedUser,
                itemAmount: prevState.itemAmount,
                item: props.item,
                firstName: prevState.firstName,
                lastName: prevState.lastName,
                email: prevState.email
            }));
        }
    }

    onFirstNameChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = $("#first-name").prop("value");
        this.setState((prevState, props) => ({
            users: prevState.users,
            selectedUser: prevState.selectedUser,
            itemAmount: prevState.itemAmount,
            item: props.item,
            firstName: name,
            lastName: prevState.lastName,
            email: prevState.email
        }));
    }

    onLastNameChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = $("#last-name").prop("value");
        this.setState((prevState, props) => ({
            users: prevState.users,
            selectedUser: prevState.selectedUser,
            itemAmount: prevState.itemAmount,
            item: props.item,
            firstName: prevState.firstName,
            lastName: name,
            email: prevState.email
        }));
    }

    onEmailChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = $("#email").prop("value");
        this.setState((prevState, props) => ({
            users: prevState.users,
            selectedUser: prevState.selectedUser,
            itemAmount: prevState.itemAmount,
            item: props.item,
            firstName: prevState.firstName,
            lastName: prevState.lastName,
            email: email
        }));
    }
    
    onUserIdChanged = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const userId = Number($("#formFamily").prop("value"));
        if (userId != null) {
            this.setState((prevState, props) => ({
                users: prevState.users,
                selectedUser: userId,
                itemAmount: prevState.itemAmount,
                item: props.item,
                firstName: prevState.firstName,
                lastName: prevState.lastName,
                email: prevState.email
            }));
        }
    }

    onItemAmountChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const donationAmount = Number($("#item-amount").prop("value"));
        this.setState((prevState, props) => ({
            users: prevState.users,
            selectedUser: prevState.selectedUser,
            itemAmount: donationAmount,
            item: props.item,
            firstName: prevState.firstName,
            lastName: prevState.lastName,
            email: prevState.email
        }));
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit}>
                <div id='create-payment-modal' className='modal' tabIndex={-1} role="dialog">
                    <div className='modal-dialog modal-dialog-center' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>Create New Payment</div>
                            <div className='modal-body'>
                                <div className="form-group">
                                    <label htmlFor="formFamily" className="col-form-label">User Account</label>
                                    <select id="formFamily" className="form-control mt-2" onChange={this.onUserIdChanged}>
                                        <option value="-1">User Not Registered</option>
                                        {this.state?.users?.map((user) =>
                                            <option key={user.id} value={user.id}>{user.lastName}, {user.firstName} ({user.email})</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="payment-method" className="text-muted form-label">Item Amount</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input id="item-amount" type="number" className="form-control" inputMode="numeric" pattern="[0-9]*" placeholder="" onChange={this.onItemAmountChanged} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">.00</span>
                                        </div>
                                    </div>
                                </div>
                                <div id="new-credit-card-form" className="form-group">
                                    <div className="form-group mt-3">
                                        <label htmlFor="name-on-card" className="text-muted form-label">First Name</label>
                                        <input type="text" className="form-control" id="first-name" name="first-name"
                                            placeholder="" inputMode="text" onChange={(e) => { this.onFirstNameChanged(e); }} />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="name-on-card" className="text-muted form-label">Last Name</label>
                                        <input type="text" className="form-control" id="last-name" name="last-name"
                                            placeholder="" inputMode="text" onChange={(e) => { this.onLastNameChanged(e); }} />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="name-on-card" className="text-muted form-label">Email</label>
                                        <input type="text" className="form-control" id="email" name="email"
                                            placeholder="" inputMode="text" onChange={(e) => { this.onEmailChanged(e); }} />
                                    </div>
                                    <div id="new-credit-card-form" className="form-group mt-2 p-2 form-control">
                                        <CardElement /> 
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <div id="image-upload-buttons" className="ml-auto">
                                    <div id="payment-loading" className="collapse">
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status"></div>
                                            <span className="ml-3 my-auto">Completing payment. Please wait...</span>
                                        </div>
                                    </div>
                                    <div id="payment-alert" className="alert alert-danger collapse mt-2"></div>
                                    <button id="payment-dismiss-button" className="btn btn-sm btn-outline-danger" onClick={e => dismissModal(e)}>Cancel</button>
                                    <button id="payment-button" className="btn btn-sm btn-outline-primary ml-4" type="submit">Submit Payment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

export default injectStripe(CreateAuctionItemPaymentModal);
