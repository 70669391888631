import React, { useState } from "react";
import ReactMde from "react-mde";
import { History } from "history";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Navbar from "../../components/navbar/Navbar"
import HistoryProps from "../../core/interfaces/HistoryProps";
import { CreateAuctionItem } from "../../core/interfaces/Auction";

import "react-mde/lib/styles/scss/react-mde-all.scss";
import AuctionService from "../../core/AuctionService";
import { isSuccess } from "../../core/interfaces/Result";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Showdown from "showdown";

function showError(message: string) {
    $("#form-alert-message").text(message);
    $("#upload-alert").show();
}

function resetError() {
    $("#form-alert").hide();
}

async function createNewAuctionItem(history: History, eventId: number, auctionId: number, title: string, content: string, startPrice: number, bidIncrement: number, reservePrice: number, isDraft: boolean) {
    resetError();

    if (title === undefined || title === "") {
        showError("Title is required.");
        return;
    }

    if (content === undefined || content === "") {
        showError("Page content is required.");
        return;
    }

    if (startPrice === undefined || startPrice < 0) {
        showError("Starting price must be greater than or equal to 0");
        return;
    }

    if (bidIncrement === undefined || bidIncrement < 0) {
        showError("Bid increment must be greater than or equal to 0");
        return;
    }

    if (reservePrice === undefined || reservePrice < 0) {
        showError("Reserve price must be greater than or equal to 0");
        return;
    }

    const payload: CreateAuctionItem = {
        auctionId: auctionId,
        title: title,
        pageContent: content,
        startingPrice: startPrice,
        bidIncrement: bidIncrement,
        reservePrice: reservePrice,
        isDraft
    };

    const service = new AuctionService();
    const result = await service.createNewAuctionItem(payload);

    if (isSuccess(result)) {
        history.replace(`/events/${eventId}/auctions/${auctionId}`);
    } else {
        showError(result.message);
    }
}

const NewAuctionItemPage: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    const pathParts = history.location.pathname.split("/");
    const eventId = pathParts[2];
    const auctionId = pathParts[4];

    const [title, setTitle] = useState();
    const [content, setContent] = useState();

    const [startPrice, setStartPrice] = useState();
    const [bidIncrement, setBidIncrement] = useState();
    const [reservePrice, setReservePrice] = useState();
    const [isDraft, setDraft] = useState();

    const onTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
    const onStartPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => setStartPrice(e.target.value);
    const onBidIncrementChanged = (e: React.ChangeEvent<HTMLInputElement>) => setBidIncrement(e.target.value);
    const onReservePriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => setReservePrice(e.target.value);

    const onPublishedStateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.id === "item-state-live" && e.target.checked) {
            setDraft(false);
        }

        if (e.target.id === "item-state-draft" && e.target.checked) {
            setDraft(true);
        }
    };

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        createNewAuctionItem(history, Number(eventId), Number(auctionId), title, content, Number(startPrice), Number(bidIncrement), Number(reservePrice), isDraft);
    };

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
    const mdConverter = new Showdown.Converter({tables: true, simplifiedAutoLink: true, tasklists: false, strikethrough: true});

    return (
        <div>
            <Navbar history={history} />
            <div className="container mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-8 mt-4">
                        <form onSubmit={formSubmit}>
                            <div className="card">
                                <div className="card-header">New Auction Item</div>
                                <div className="card-body">
                                    <div id="form-alert" className="alert alert-danger collapse">
                                            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /><span id="form-alert-message"></span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-title">Title</label>
                                        <input id="item-title" name="item-title" type="text" className="form-control" 
                                                value={title} onChange={e => onTitleChanged(e)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-content">Page Content</label>
                                        <ReactMde onChange={setContent}
                                            value={content}
                                            selectedTab={selectedTab}
                                            onTabChange={setSelectedTab}
                                            generateMarkdownPreview={(markdown) => Promise.resolve(mdConverter.makeHtml(markdown))} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-start-price">Starting Price</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="item-start-price" name="item-start-price" type="text" className="form-control"
                                                    value={startPrice} onChange={onStartPriceChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-price-increment">Bid Increment</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="item-bid-increment" name="item-bid-increment" type="text" className="form-control"
                                                    value={bidIncrement} onChange={onBidIncrementChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-reserve-price">Reserve Price</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="item-reserve-price" name="item-reserve-price" type="text" className="form-control"
                                                    value={reservePrice} onChange={onReservePriceChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="is-draft">Published</label><br/>
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label className="btn btn-light active">
                                                <input type="radio" name="item-state" id="item-state-live" onChange={onPublishedStateChanged} /> Live
                                            </label>
                                            <label className="btn btn-light">
                                                <input type="radio" name="item-state" id="item-state-draft" onChange={onPublishedStateChanged} /> Draft
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex w-100">
                                        <div className="ml-auto">
                                            <button className="btn btn-sm btn-outline-danger">Cancel</button>
                                            <button className="btn btn-sm btn-outline-primary ml-4" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewAuctionItemPage);
