import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Authenticator from "../../core/Authenticator";
import HistoryProps from "../../core/interfaces/HistoryProps";
import { isError } from "../../core/interfaces/Result";

import galaLogo from "../../images/gala_logo.svg";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

async function login(history: History, email: string, password: string) {
    resetError();
    showLoadingProgress();

    if (email === undefined || email === "") {
        hideLoadingProgress();
        showError("Email address is required.");
        return;
    }

    if (password === undefined || password === "") {
        hideLoadingProgress();
        showError("Password is required.");
        return;
    }

    const authenticator = new Authenticator();
    
    const result = await authenticator.authenticate(email, password);
    if (isError(result)) {
        hideLoadingProgress();
        showError(result.message);
        return;
    }

    history.replace("/");
}

function showLoadingProgress() {
    $("#login-button").hide();
    $("#forgot-password").hide();
    $("#login-loading").show();
}

function hideLoadingProgress() {
    $("#login-loading").hide();
    $("#login-button").show();
    $("#forgot-password").show();
}

function showError(message: string) {
    $("#login-alert-message").text(message);
    $("#login-alert").show();
}

function resetError() {
    $("#login-alert").hide();
}

const LoginPage: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    const [email, setEmail] = useState();
    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    const [password, setPassword] = useState();
    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const onSubmitLogin = (event: React.FormEvent) => {
        event.preventDefault();
        login(history, email, password);
    };

    return (
        <div className="container">
            <div className="row mt-4 justify-content-center align-items-center">
                <div className="col-lg-4">
                    <img src={galaLogo} alt="St. Martin de Porres School Gala & Auction" className="col-12" />
                    <div className="card mt-4">
                        <div className="card-body">
                            <div id="login-alert" className="alert alert-danger collapse">
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /><span id="login-alert-message"></span>
                            </div>
                            <form onSubmit={onSubmitLogin}>
                                <input id="email-field" type="email" className="form-control mt-2" placeholder="Email" onChange={onEmailChange} />
                                <input id="password-field" type="password" className="form-control mt-2" placeholder="Password" onChange={onPasswordChange} />
                                <div className="mt-4 text-center">
                                    <div className="d-flex justify-content-center">
                                        <div id="login-loading" className="spinner-border text-primary collapse" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <button id="login-button" type="submit" className="btn btn-primary col-12">Login</button>
                                    <p id="forgot-password" className="mt-3">
                                        <a className="psa-inline-button" href="/password/reset">
                                            Forgot password? Click here to reset.
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginPage);


