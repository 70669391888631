import { GalleryItem } from "./interfaces/GalleryItem";
import { Result } from "./interfaces/Result";
import { ServiceError } from "./interfaces/Error";
import Authenticator from "./Authenticator";

const axios = require("axios").default;

export default class GalleryService {
    async uploadNewImage(name: string, file: File): Promise<Result<GalleryItem>> {
        const authenticator = new Authenticator();

        try {
            const data = new FormData();
            data.append("imageName", name);
            data.append("image", file);

            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/gallery/upload`, data, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const item: GalleryItem = response.data;
                return item;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, "Unable to upload new image.");
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async addImageToAuctionItem(galleryItemId: number, auctionItemId: number): Promise<Result<boolean>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/gallery`, {
                "galleryItemId": galleryItemId,
                "auctionItemId": auctionItemId
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                return true;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.repsonse != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async deleteImage(imageId: number, auctionItemId: number): Promise<Result<boolean>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/gallery/${imageId}/delete`, {
                auction_item_id: auctionItemId
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                return true;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }
}
