import React from "react";

import { Auction } from "../core/interfaces/Auction";
import AuctionListItem from "../components/AuctionListItem";

interface AuctionsListProps {
    auctions: Auction[];
}

const AuctionsList: React.FC<AuctionsListProps> = ({auctions}: AuctionsListProps) => {
    return (
        <div className="list-group list-group-flush">
        {auctions.map((auction) =>
            <AuctionListItem key={auction.id} auction={auction} />
        )}
        </div>
    );
}

export default AuctionsList;
