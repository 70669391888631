import React from "react";

import { Ticket } from "../core/interfaces/Ticket";
import TicketListItem from "./TicketListItem";

interface TicketsCardProps {
    tickets: Ticket[];
}

const TicketsCard: React.FC<TicketsCardProps> = ({tickets: tickets}: TicketsCardProps) => {
    let ticketTotal = 0;
    tickets.map((ticket) => {
        ticketTotal += ticket.quantityGuests;
    });

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex w-100">
                    <span>Tickets</span>
                    <span className="font-weight-bold ml-auto">Total Tickets: {ticketTotal}</span>
                </div>
                <div className="card-body m-0 p-0">
                    <div id="tickets-accordion" className="list-group list-group-flush accordion">
                        {tickets.map((ticket) =>
                        <TicketListItem ticket={ticket} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketsCard;
