import { Result } from "./interfaces/Result";
import { User } from "./interfaces/User";
import { ServiceError } from "./interfaces/Error";

const axios = require("axios").default;

export default class Authenticator {
    isAuthenticated(): boolean {
        const token = localStorage.getItem(`${process.env.REACT_APP_API_TOKEN_KEY}`);
        return token != null && token.length > 0;
    }

    logout() {
        localStorage.removeItem(`${process.env.REACT_APP_API_TOKEN_KEY}`);
        localStorage.removeItem(`${process.env.REACT_APP_USER_KEY}`);
    }

    getToken(): string | null {
        return localStorage.getItem(`${process.env.REACT_APP_API_TOKEN_KEY}`);
    }

    async authenticate(email: string, password: string): Promise<Result<User>> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/users/authenticate`, {
                "email": email,
                "password": password
            }, {
                headers: {
                    "X-Site-Realm": "admin"
                }
            });

            if (response.status === 200) {
                const user: User = response.data;
                localStorage.setItem(`${process.env.REACT_APP_USER_KEY}`, JSON.stringify(user));
                localStorage.setItem(`${process.env.REACT_APP_API_TOKEN_KEY}`, response.headers.authorization);

                return user;
            } else {
                return new ServiceError(response.status, "Unable to authenticate the user");
            }
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            } else {
                return new ServiceError(-1, "Unable to make the network request.");
            }
            
        }
    }

    async requestPasswordReset(email: string): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/password/reset`, {
                "email": email
            });

            if (response.status === 202) {
                return true;
            }
        } catch (error) {
            console.log("Error requesting password reset: " + error.message);
        }

        return false;
    }

    async changePassword(newPassword: string, token: string): Promise<Result<boolean>> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/users/password`, {
                "password": newPassword,
                "tokenDigest": token
            });

            if (response.status === 200) {
                return true;
            } else {
                return new ServiceError(response.status, response.data.message);
            }
        } catch (error) {
            return new ServiceError(error.response.status, error.response.data.message);
        }
    }

    async getUsers(): Promise<Result<User[]>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/admin/users`, {
                headers: {
                    "Authorization": this.getToken()
                }
            });

            if (response.status === 200) {
                const users: User[] = response.data;
                return users;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }
}
