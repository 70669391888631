import React from "react";
import { History } from "history";

import Authenticator from "../../core/Authenticator";
import HistoryProps from "../../core/interfaces/HistoryProps";

function executeLogout(e: React.MouseEvent, history: History) {
    e.preventDefault();

    const authenticator = new Authenticator();
    authenticator.logout();

    history.replace("/login");
}

const Navbar: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-blue flex-shrink-0">
            <a className="navbar-brand" href="/">
                <span className="ml-2">Gala & Auction Admin</span>
            </a>
            <div className="my-2 my-lg-0 ml-auto">
                <button className="btn btn-sm btn-outline-light ml-3" onClick={(e) => executeLogout(e, history)}>Logout</button>
            </div>
        </nav>
    );
};

export default Navbar;
