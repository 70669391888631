import { Auction, CreateAuctionItem, AuctionItem, UpdateAuctionItem, Bid, EventAuction, AuctionItemCharge } from "./interfaces/Auction";
import { GalleryItem } from "./interfaces/GalleryItem";
import { Result } from "./interfaces/Result";
import { ServiceError } from "./interfaces/Error";
import Authenticator from "./Authenticator";
import { Transaction } from "./interfaces/Transaction";

const axios = require("axios").default;

export default class AuctionService {
    async getAuctionsForEvent(eventId: number): Promise<Result<Auction[]>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/events/${eventId}/auctions`);
            if (response.status === 200) {
                const auctions: Auction[] = response.data;
                return auctions;
            }

            return new ServiceError(response.status, "Unable to fetch events list.");
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getAuctionEvents(): Promise<Result<EventAuction[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-events`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });
            if (response.status === 200) {
                const auctions: EventAuction[] = response.data;
                return auctions;
            }

            return new ServiceError(response.status, "Unable to fetch event auctions list");
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }

    async getAuction(auctionId: number): Promise<Result<Auction>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auctions/${auctionId}`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const auction: Auction = response.data;
                return auction;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }

    async getAuctionItems(auctionId: number): Promise<Result<AuctionItem[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auctions/${auctionId}/items`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });
            if (response.status === 200) {
                const items: AuctionItem[] = response.data;
                return items;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getBids(auctionItemId: number): Promise<Result<Bid[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/${auctionItemId}/bids`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const bids: Bid[] = response.data;
                return bids;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getAuctionItem(auctionId: number, itemId: number): Promise<Result<AuctionItem>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auctions/${auctionId}/items/${itemId}`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const item: AuctionItem = response.data;
                return item;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getAuctionItemTransactions(itemId: number): Promise<Result<Transaction[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/${itemId}/transactions`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                return response.data as Transaction[];
            }

            return [];
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getImagesForAuctionItem(auctionItemId: number): Promise<Result<GalleryItem[]>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/${auctionItemId}/gallery`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const items: GalleryItem[] = response.data;
                return items;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async createNewAuctionItem(item: CreateAuctionItem): Promise<Result<AuctionItem>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auctions/items`, {
                "auctionId": item.auctionId,
                "title": item.title,
                "pageContent": item.pageContent,
                "startingPrice": item.startingPrice,
                "bidIncrement": item.bidIncrement,
                "reservePrice": item.reservePrice,
                "isDraft": item.isDraft
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const createdItem: AuctionItem = response.data;
                return createdItem;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async updateAuctionItem(item: UpdateAuctionItem): Promise<Result<AuctionItem>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.put(`${process.env.REACT_APP_GALA_API_HOST}/auctions/items`, {
                "auctionItemId": item.auctionItemId,
                "title": item.title,
                "pageContent": item.pageContent,
                "startingPrice": item.startingPrice,
                "bidIncrement": item.bidIncrement,
                "reservePrice": item.reservePrice,
                "isDraft": item.isDraft,
                "isFeatured": item.isFeatured,
                "winnerBidNumber": item.winnerBidNumber,
                "winningPrice": item.winningPrice
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const updatedItem: AuctionItem = response.data;
                return updatedItem;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async copyAuctionItem(itemId: number, auctionId: number): Promise<Result<string>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auctions/items/copy`, {
                "id": itemId,
                "auction_id": auctionId
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 201) {
                const url = response.data['url'];
                return url;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }

    async deleteAuctionItem(itemId: number): Promise<Result<boolean>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auctions/items/delete`, {
                "id": itemId
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                return true;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }

    async chargeForAuctionItem(payload: AuctionItemCharge): Promise<Result<boolean>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auctions/items/charge`, payload, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 201) {
                return true;
            }

            return false;
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }
}
