import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

import HistoryProps from "../../core/interfaces/HistoryProps";

import galaLogo from "../../images/gala_logo.svg";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Authenticator from "../../core/Authenticator";

async function requestPasswordReset(email: string, history: History) {
    resetError();
    showLoadingProgress();

    if (email === undefined || email === "") {
        hideLoadingProgress();
        showError("Email is required.");
        return;
    }

    const authenticator = new Authenticator();

    const result = await authenticator.requestPasswordReset(email);
    if (result) {
        hideLoadingProgress();
        showSuccess("If your email is registered with the site, you'll receive a password reset link momentarily.");
    } else {
        hideLoadingProgress();
        showError("There was an error processing your request. Please try again.");
    }
}

function resetError() {
    $("#reset-alert").hide();
}

function showError(message: string) {
    $("#reset-alert-message").text(message);
    $("#reset-alert").show();
}

function showSuccess(message: string) {
    $("#reset-button").hide();
    $("#reset-success-message").text(message);
    $("#reset-success").show();
}

function showLoadingProgress() {
    $("#reset-button").hide();
    $("#reset-loading").show();
}

function hideLoadingProgress() {
    $("#reset-loading").hide();
    $("#reset-button").show();
}

const NewPasswordResetPage: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    const [email, setEmail] = useState();
    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    const onSubmitPasswordReset = (event: React.FormEvent) => {
        event.preventDefault();
        requestPasswordReset(email, history);
    };

    return (
        <div className="container">
            <div className="row mt-4 justify-content-center align-items-center">
                <div className="col-lg-4">
                    <img src={galaLogo} alt="St. Martin de Porres School Gala & Auction" className="col-12" />
                    <div className="card mt-4">
                        <div className="card-body">
                            <div id="reset-alert" className="alert alert-danger collapse">
                                <div className="row">
                                    <div className="col-1">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                    </div>
                                    <div className="col">
                                        <span id="reset-alert-message"></span>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={onSubmitPasswordReset}>
                                <input id="email-field" type="email" className="form-control mt-2" placeholder="Email" onChange={onEmailChange} />
                                <div className="mt-4 text-center">
                                    <div className="d-flex justify-content-center">
                                        <div id="reset-loading" className="spinner-border text-primary collapse" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div id="reset-success" className="alert alert-success collapse">
                                        <div className="row">
                                            <div className="col-1">
                                                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                            </div>
                                            <div className="col text-left">
                                                <span id="reset-success-message"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <button id="reset-button" type="submit" className="btn btn-primary col-12">Send Password Reset</button>
                                    <p id="contact-support" className="mt-3">
                                        <a className="psa-inline-button" href="mailto:help@stmartinpsa.org">
                                            Need help accessing your account?
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewPasswordResetPage);
