import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/navbar/Navbar";
import Toolbar from "../../components/toolbar/Toolbar";
import AttendanceCard from "../../components/AttendanceCard";

import EventService from "../../core/EventService";
import { Event } from "../../core/interfaces/Event";
import { isSuccess } from "../../core/interfaces/Result";
import AuctionsCard from "../../components/auctions-card/AuctionsCard";
import TicketsCard from "../../components/TicketsCard";
import { Ticket } from "../../core/interfaces/Ticket";
import TicketService from "../../core/TicketService";
import { Attendance } from "../../core/interfaces/Attendance";

interface DashboardPageProps {
    history: History;
}

async function loadEventList(setEvents: React.Dispatch<Event[]>, setSelectedEvent: React.Dispatch<Event>) {
    const service = new EventService();

    const result = await service.getEvents();
    if (isSuccess(result)) {
        setEvents(result);

        result.forEach((event) => {
            if (event.isActive) {
                setSelectedEvent(event);
            }
        });
    }
}

async function loadTickets(setTickets: React.Dispatch<Ticket[]>, selectedEvent: Event | undefined) {
    const service = new TicketService();
    if (selectedEvent === undefined) {
        return;
    }

    const result = await service.getTickets(selectedEvent);
    if (isSuccess(result)) {
        setTickets(result);
    }
}

async function loadAttendance(setAttendees: React.Dispatch<Attendance[]>, selectedEvent: Event | undefined) {
    const service = new TicketService();
    if (selectedEvent === undefined) {
        return;
    }

    const result = await service.getAttendance(selectedEvent);
    if (isSuccess(result)) {
        setAttendees(result);
    }
}

const DashboardPage: React.FC<DashboardPageProps> = ({history}: DashboardPageProps) => {
    const [events, setEvents] = useState<Event[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<Event>();

    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [attendees, setAttendees] = useState<Attendance[]>([]);

    const onAttendeesChanged = async () => {
        loadAttendance(setAttendees, selectedEvent);
    };

    useEffect(() => {
        loadEventList(setEvents, setSelectedEvent);
    }, []);

    useEffect(() => {
        loadTickets(setTickets, selectedEvent);
        loadAttendance(setAttendees, selectedEvent);
    }, [selectedEvent]);

    return (
        <div>
            <Navbar history={history} />
            <Toolbar events={events} setSelectedEvent={setSelectedEvent} />
            <div className="container mt-4 mb-4">
                <div className="row">
                    <div className="col-lg-4">
                        <AuctionsCard event={selectedEvent!} />
                        <AttendanceCard attendees={attendees} onAttendeesChanged={onAttendeesChanged} />
                    </div>
                    <div className="col-lg-8 margin-top-sm">
                        <TicketsCard tickets={tickets} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(DashboardPage);
