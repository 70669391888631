import React from "react";

import { Ticket } from "../core/interfaces/Ticket";

interface TicketListItemProps {
    ticket: Ticket;
}

const TicketListItem: React.FC<TicketListItemProps> = ({ticket}: TicketListItemProps) => {
    return (
        <li id={"ticket-"+ticket.id} className="list-group-item flex-column align-items-start pointer">
            <div data-toggle="collapse" data-target={"#ticket-detail-"+ticket.id}>
                <div className="d-flex w-100">
                    <p>{ticket.name}</p>
                    <p className="ml-auto">
                        <span className="badge badge-primary">{ticket.quantityGuests}</span>
                    </p>
                </div>
                <div className="mt-n3">
                    <p className="text-muted" style={{ marginBottom: 0, paddingBottom: 0 }}>Purchased by: {ticket.user.firstName} {ticket.user.lastName}</p>
                    <p className="text-muted" style={{ margin: 0, padding: 0}}>{ticket.user.email}</p>
                </div>
            </div>
            <div id={"ticket-detail-"+ticket.id} className="collapse" data-parent="#tickets-accordion">
                <h5 style={{ marginTop: '8px' }}>Seating Notes</h5>
                <p>{ticket.seatingNotes}</p>
                <br/>
                <h5>Grade(s) of children</h5>
                <p>{ticket.childGrade ?? 'N/A'}</p>
            </div>
        </li>
    );
}

export default TicketListItem;
