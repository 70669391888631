import React from "react";
import { Attendance } from "../core/interfaces/Attendance";

interface AttendanceListItemProps {
    attendee: Attendance;
}

const AttendanceListItem: React.FC<AttendanceListItemProps> = ({attendee}: AttendanceListItemProps) => {
    return (
        <li className="list-group-item flex-column align-items-start">
            <p>
                <span className="badge badge-success mr-2">{attendee.bidNumber}</span>
                <span className="align-middle">{attendee.name}</span>
                <br/>
                {attendee.user.id > 0 &&
                    <small className="text-muted font-italic">Registered User</small>
                }
            </p>
        </li>
    );
}

export default AttendanceListItem;
