import React from "react";

import GalleryItemView from "./GalleryItemView";
import { GalleryItem } from "../core/interfaces/GalleryItem";

interface GalleryCollectionProps {
    images: GalleryItem[];
    auctionItemId: number;
    refreshImages: Function;
}

const GalleryCollection: React.FC<GalleryCollectionProps> = ({images, auctionItemId, refreshImages}: GalleryCollectionProps) => {
    return (
        <div className="m-0 p-0">
            <ul className="list-group list-group-flush m-0 p-0">
            {images.map((image) =>
                <GalleryItemView key={image.id} image={image} auctionItemId={auctionItemId} refreshImages={refreshImages} />
            )}
            {images.length === 0 && 
                <li className="list-group-item flex-column mx-auto empty-list">
                    <p className="text-muted mt-3">No Images.</p>
                </li>
            }
            </ul>
        </div>
    );
}

export default GalleryCollection;
