import React from "react";
import { GalleryItem } from "../core/interfaces/GalleryItem";
import GalleryService from "../core/GalleryService";
import $ from "jquery";
import { isSuccess } from "../core/interfaces/Result";

interface GalleryItemViewProps {
    image: GalleryItem;
    auctionItemId: number;
    refreshImages: Function;
}

async function deleteImage(imageId: number, auctionItemId: number, refreshImages: Function) {
    const service = new GalleryService();
    
    const result = await service.deleteImage(imageId, auctionItemId);
    if (isSuccess(result)) {
        refreshImages();
        $("#delete-confirm-modal").modal("hide");
    }
}

function dismissUpload(event: React.MouseEvent) {
    event.preventDefault();
    $("#image-upload-modal").modal("hide");
}

const GalleryItemView: React.FC<GalleryItemViewProps> = ({image, auctionItemId, refreshImages}: GalleryItemViewProps) => {
    const onDeleteClicked = (event: React.MouseEvent) => {
        event.preventDefault();
        $("#delete-confirm-modal").modal("show");
    };

    const onConfirmedDelete = (event: React.MouseEvent, imageId: number) => {
        event.preventDefault();
        deleteImage(imageId, auctionItemId, refreshImages);
    };

    return (
        <li className="list-group-item flex-column align-items-start">
            <img src={image.url} width="100%" />
            <div className="mt-2 d-flex w-100 justify-content-between">
                <small className="text-muted my-auto">{image.title}</small>
                <button className="btn btn-danger btn-sm" onClick={(e) => onDeleteClicked(e)}>Delete Image</button>
            </div>
            <div id="delete-confirm-modal" className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Image</h5>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete the image?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"
                                onClick={(e) => { onConfirmedDelete(e, image.id) }}>Delete</button>
                            <button type="button" className="btn btn-light" data-dismiss="modal"
                                onClick={(e) => { dismissUpload(e) }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default GalleryItemView;
