import React, { useEffect, useState } from "react";

import { Auction } from "../../core/interfaces/Auction";
import { Event } from "../../core/interfaces/Event";
import AuctionService from "../../core/AuctionService";
import { isSuccess } from "../../core/interfaces/Result";

import AuctionsList from "../AuctionsList";

interface AuctionsCardProps {
    event: Event;
}

async function loadAuctionsForEvent(eventId: number, setAuctions: React.Dispatch<Auction[]>) {
    const service = new AuctionService();

    const result = await service.getAuctionsForEvent(eventId);
    if (isSuccess(result)) {
        setAuctions(result);
    }
}

const AuctionsCard: React.FC<AuctionsCardProps> = ({event}: AuctionsCardProps) => {
    const [auctions, setAuctions] = useState<Auction[]>([]);

    useEffect(() => {
        if (event != null) {
            loadAuctionsForEvent(event.id, setAuctions);
        }
    }, [event]);

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    Auctions
                </div>
                <div className="card-body m-0 p-0">
                    <div className="container m-0 p-0">
                        <AuctionsList auctions={auctions} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuctionsCard;
