import React, { useState, useEffect } from "react";
import $ from "jquery";

import AttendanceListItem from "./AttendanceListItem";
import { Attendance } from "../core/interfaces/Attendance";
import { User } from "../core/interfaces/User";
import Authenticator from "../core/Authenticator";
import { isSuccess } from "../core/interfaces/Result";
import TicketService from "../core/TicketService";

function showNewAttendeeModal(event: React.MouseEvent) {
    event.preventDefault();
    $("#new-attendee-modal").modal("show");
}

function dismissModal(event: React.MouseEvent) {
    event.preventDefault();
    $("#new-attendee-modal").modal("hide");
}

async function newAttendee(name: string, userId: number, bidNumber: number, onAttendeesChanged: Function) {
    $("#attendee-buttons").hide();
    $("#attendee-progress").show();

    const service = new TicketService();

    const result = await service.addAttendee(name, userId, bidNumber);
    if (isSuccess(result)) {
        onAttendeesChanged();

        $("#attendee-buttons").show();
        $("#attendee-progress").hide();
        $("#new-attendee-modal").modal("hide");
    }
}

async function fetchUsers(setUsers: React.Dispatch<User[]>) {
    const authenticator = new Authenticator();
    
    const result = await authenticator.getUsers();
    if (isSuccess(result)) {
        setUsers(result);
    }
}

interface AttendanceCardProps {
    attendees: Attendance[];
    onAttendeesChanged: Function;
}

const AttendanceCard: React.FC<AttendanceCardProps> = ({attendees, onAttendeesChanged}: AttendanceCardProps) => {
    const [users, setUsers] = useState<User[]>([]);

    const [name, setName] = useState();
    const [bidNumber, setBidNumber] = useState();
    const [userId, setUserId] = useState();

    const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
    const onBidNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => setBidNumber(e.target.value);
    const onUserIdChanged = (e: React.ChangeEvent<HTMLSelectElement>) => setUserId(e.target.value);

    useEffect(() => {
        fetchUsers(setUsers);
    }, []);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        newAttendee(name, Number(userId), Number(bidNumber), onAttendeesChanged);
    };

    return (
        <div className="mt-4">
            <div id="new-attendee-modal" className="modal" tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form onSubmit={formSubmit}>
                            <div className="modal-header">New Attendee</div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="attendee-name">Name</label>
                                    <input type="text" className="form-control" name="attendee-name" onChange={onNameChanged} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formFamily" className="col-form-label">Registered User</label>
                                    <select id="formFamily" className="form-control mt-2" onChange={onUserIdChanged}>
                                        <option value="-1">User Not Registered</option>
                                        {users.map((user) =>
                                            <option key={user.id} value={user.id}>{user.lastName}, {user.firstName}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="attendee-bid-number">Bid Number</label>
                                    <input type="text" className="form-control" name="attendee-bid-number" onChange={onBidNumberChanged} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="d-flex w-100">
                                    <div id="attendee-buttons" className="ml-auto">
                                        <button className="btn btn-sm btn-outline-danger" onClick={e => dismissModal(e)}>Cancel</button>
                                        <button className="btn btn-sm btn-outline-primary ml-4" type="submit">Create</button>
                                    </div>
                                    <div id="attendee-progress" className="ml-auto collapse">
                                        <div id="attendee-loading" className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header d-flex w-100">
                    <span className="align-middle">Attendance</span>
                    <button className="ml-auto btn btn-sm btn-outline-primary" onClick={(e) => showNewAttendeeModal(e)}>New Attendee</button>
                </div>
                <div className="card-body m-0 p-0">
                    <div className="list-group list-group-flush">
                        {attendees.length === 0 &&
                        <li className="list-group-item flex-column mx-auto empty-list">
                            <p className="text-muted mt-3">No attendees</p>
                        </li>
                        }
                        {attendees.map((attendee) =>
                        <AttendanceListItem attendee={attendee} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttendanceCard;
