import React, { useState, useEffect } from "react";
import $ from "jquery";
import moment from "moment";

import { Auction } from "../core/interfaces/Auction";

function enableForm(event: React.MouseEvent) {
    event.preventDefault();
    
    $("#auction-type").prop("disabled", false);
    $("#auction-start").prop("disabled", false);
    $("#auction-end").prop("disabled", false);

    $("#auction-form-fields").show();
    $("#details-edit-button").hide();
}

function disableForm(event: React.FormEvent) {
    event.preventDefault();

    $("#auction-form-fields").hide();
    $("#details-edit-button").show();

    $("#auction-type").prop("disabled", true);
    $("#auction-start").prop("disabled", true);
    $("#auction-end").prop("disabled", true);
}

interface AuctionDetailFormProps {
    auction: Auction | undefined;
}

const AuctionDetailForm: React.FC<AuctionDetailFormProps> = ({auction}: AuctionDetailFormProps) => {
    const [auctionType, setAuctionType] = useState<string>();
    const [startTime, setStartTime] = useState<string>();
    const [endTime, setEndTime] = useState<string>();

    useEffect(() => {
        if (auction !== undefined) {
            setAuctionType(auction.auctionType);

            const start = new Date(auction.startTime * 1000);
            setStartTime(moment(start).format("YYYY-MM-DDTHH:mm"));

            const end = new Date(auction.endTime * 1000);
            setEndTime(moment(end).format("YYYY-MM-DDTHH:mm"));
        }
    }, [auction]);

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex w-100">
                    <span>Details</span>
                    <button id="details-edit-button" className="btn btn-sm btn-outline-primary ml-auto collapse" onClick={e => enableForm(e)}>Edit</button>
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group">
                            <label htmlFor="auction-name">Auction Type</label>
                            <select id="auction-type" name="auction-type" className="form-control" value={auctionType} disabled>
                                <option value="Online Auction">Online Auction</option>
                                <option value="Live Auction">Live Auction</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="auction-start">Auction Starts</label>
                            <input id="auction-start" name="auction-start" type="datetime-local" value={startTime} className="form-control" disabled />
                        </div>
                        <div className="form-group">
                            <label htmlFor="auction-end">Auction Ends</label>
                            <input id="auction-end" name="auction-end" type="datetime-local" value={endTime} className="form-control" disabled />
                        </div>
                    </form>
                </div>
                <div id="auction-form-fields" className="card-footer collapse">
                    <div className="d-flex w-100">
                        <div className="ml-auto">
                            <button className="btn btn-sm btn-outline-danger" onClick={e => disableForm(e)}>Cancel</button>
                            <button className="btn btn-sm btn-outline-primary ml-4">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuctionDetailForm;
