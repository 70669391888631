import React from "react";
import { History } from "history";
import { AuctionItem } from "../core/interfaces/Auction";
import AuctionItemListItem from "./AuctionItemListItem";

interface AuctionItemsListProps {
    eventId: number;
    auctionId: number;

    auctionItems: AuctionItem[];
    history: History;
}

function openNewItemPage(event: React.MouseEvent, history: History, eventId: number, auctionId: number) {
    event.preventDefault();
    history.push(`/events/${eventId}/auctions/${auctionId}/items/new`);
}

const AuctionItemsList: React.FC<AuctionItemsListProps> = ({eventId, auctionId, auctionItems, history}: AuctionItemsListProps) => {
    return (
        <div>
            <div className="card">
                <div className="card-header d-flex w-100">
                    <span className="mt-1">Auction Items</span>
                    <button className="btn btn-sm btn-outline-primary ml-auto" onClick={e => openNewItemPage(e, history, eventId, auctionId)}>New Item</button>
                </div>
                <div className="card-body m-0 p-0">
                    <ul className="list-group list-group-flush m-0 p-0">
                    {auctionItems.map((item) =>
                        <AuctionItemListItem key={item.id} auctionItem={item} />
                    )}
                    {auctionItems.length === 0 &&
                        <li className="list-group-item flex-column mx-auto empty-list">
                            <p className="text-muted mt-3">No Auction Items.</p>
                        </li>
                    }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AuctionItemsList;
