import React, { useEffect, useState } from "react";
import ReactMde from "react-mde";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Showdown from "showdown";

import { UpdateAuctionItem } from "../../core/interfaces/Auction";
import GalleryCollection from "../../components/GalleryCollection";
import HistoryProps from "../../core/interfaces/HistoryProps";
import Navbar from "../../components/navbar/Navbar";
import GalleryService from "../../core/GalleryService";
import { isSuccess } from "../../core/interfaces/Result";
import { AuctionItem } from "../../core/interfaces/Auction";
import { GalleryItem } from "../../core/interfaces/GalleryItem";

import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import AuctionService from "../../core/AuctionService";
import { isError } from "util";

function showUpload(event: React.MouseEvent) {
    event.preventDefault();
    $("#image-upload-modal").modal("show");
}

function dismissUpload(event: React.MouseEvent) {
    event.preventDefault();
    $("#image-upload-modal").modal("hide");
}

async function uploadFile(auctionItemId: number, name: string, file: File) {
    resetError();

    if (name == null || name === "") {
        showError("Name is required.");
        return;
    }

    if (file == null) {
        showError("File is required");
        return;
    }

    $("#image-upload-buttons").hide();
    $("#image-upload-progress").show();

    const service = new GalleryService();
    const result = await service.uploadNewImage(name, file);

    if (isSuccess(result)) {
        const auctionItemResult = await service.addImageToAuctionItem(result.id, auctionItemId);
        if (isError(auctionItemResult)) {
            showError(auctionItemResult.message);
        }
    }

    $("#image-upload-buttons").show();
    $("#image-upload-progress").hide();

    if (isSuccess(result)) {
        $("#image-upload-modal").modal("hide");
    } else {
        showError(result.message);
    }
}

async function updateAuctionItem(auctionItemId: number, history: History, title: string, content: string, startPrice: number, bidIncrement: number, reservePrice: number, isDraft: boolean, isFeatured: boolean, winnerBidNumber: number, winningPrice: number) {
    resetError();

    if (title === undefined || title === "") {
        showError("Title is required.");
        return;
    }

    if (content === undefined || content === "") {
        showError("Page content is required.");
        return;
    }

    if (startPrice === undefined || startPrice < 0) {
        showError("Starting price must be greater than or equal to 0");
        return;
    }

    if (bidIncrement === undefined || bidIncrement < 0) {
        showError("Bid increment must be greater than or equal to 0");
        return;
    }

    if (reservePrice === undefined || reservePrice < 0) {
        showError("Reserve price must be greater than or equal to 0");
        return;
    }

    const payload: UpdateAuctionItem = {
        auctionItemId: auctionItemId,
        title: title,
        pageContent: content,
        startingPrice: startPrice,
        bidIncrement: bidIncrement,
        reservePrice: reservePrice,
        isDraft: isDraft,
        isFeatured: isFeatured,
        winnerBidNumber: winnerBidNumber,
        winningPrice: winningPrice
    };

    const service = new AuctionService();
    const result = await service.updateAuctionItem(payload);

    if (isSuccess(result)) {
        showSuccess("Item successfully updated.");
    } else {
        showError(result.message);
    }
}

function showSuccess(message: string) {
    $("#form-alert-message").text(message);
    $("#form-alert").show();
}

function showError(message: string) {
    $("#upload-alert-message").text(message);
    $("#upload-alert").show();
}

function resetError() {
    $("#upload-alert").hide();
}

async function loadAuctionItem(history: History, auctionId: number, auctionItemId: number, setAuctionItem: Function, setImages: React.Dispatch<GalleryItem[]>) {
    const service = new AuctionService();

    const result = await service.getAuctionItem(auctionId, auctionItemId);
    if (isSuccess(result)) {
        setAuctionItem(result);

        const galleryResult = await service.getImagesForAuctionItem(auctionItemId);
        if (isSuccess(galleryResult)) {
            setImages(galleryResult);
        }
    } else {
        history.goBack();
    }
}

const EditAuctionItemPage: React.FC<HistoryProps> = ({history}: HistoryProps) => {
    const pathParts = history.location.pathname.split("/");
    const auctionId = pathParts[2];
    const auctionItemId = pathParts[4];

    const [fileName, setFileName] = useState();
    const onFileNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value);

    const [file, setFile] = useState();
    const [filePath, setFilePath] = useState();

    const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null) {
            const file = e.target.files[0];
            setFile(file);
            setFilePath(file.name);

            $("#custom-file-label").text(file.name);
        }
    };

    const [title, setTitle] = useState();
    const [content, setContent] = useState();

    const [startPrice, setStartPrice] = useState();
    const [bidIncrement, setBidIncrement] = useState();
    const [reservePrice, setReservePrice] = useState();
    const [publishState, setPublishState] = useState();
    const [featuredState, setFeaturedState] = useState();

    const [auctionType, setAuctionType] = useState();

    const [winnerBidNumber, setWinnerBidNumber] = useState();
    const [winningPrice, setWinningPrice] = useState();

    const onTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
    const onStartPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => setStartPrice(e.target.value);
    const onBidIncrementChanged = (e: React.ChangeEvent<HTMLInputElement>) => setBidIncrement(e.target.value);
    const onReservePriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => setReservePrice(e.target.value);

    const onPublishedStateChanged = (e: React.MouseEvent<HTMLInputElement>) => {
        console.log("Published state: " + e.currentTarget.value);
        setPublishState(e.currentTarget.value);
    };

    const onFeaturedStateChanged = (e: React.MouseEvent<HTMLInputElement>) => {
        setFeaturedState(e.currentTarget.value);
    };

    const onWinnerBidNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => setWinnerBidNumber(e.target.value);
    const onWinningPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => setWinningPrice(e.target.value);

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
    const mdConverter = new Showdown.Converter({tables: true, simplifiedAutoLink: true, tasklists: false, strikethrough: true});

    const setAuctionItem = (item: AuctionItem) => {
        if (item.isDraft) {
            setPublishState("draft");
            $("#btn-draft").addClass("active");
        } else {
            setPublishState("live");
            $("#btn-live").addClass("active");
        }

        if (item.isFeatured) {
            setFeaturedState("yes");
            $("#btn-featured").addClass("active");
        } else {
            setFeaturedState("no");
            $("#btn-not-featured").addClass("active");
        }

        setTitle(item.title);
        setContent(item.pageContent);
        setStartPrice(item.startingPrice);
        setBidIncrement(item.bidIncrement);
        setReservePrice(item.reservePrice);
        setAuctionType(item.auction.auctionType);
        setWinnerBidNumber(item.winnerBidNumber);
        setWinningPrice(item.winningPrice);
    };

    const [images, setImages] = useState<GalleryItem[]>([]);

    const uploadAndRefresh = async () => {
        await uploadFile(Number(auctionItemId), fileName, file);
        await loadAuctionItem(history, Number(auctionId), Number(auctionItemId), setAuctionItem, setImages);
    };

    const fileUploadSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        uploadAndRefresh();
    };

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        updateAuctionItem(Number(auctionItemId), history, title, content, Number(startPrice), Number(bidIncrement), Number(reservePrice), publishState === "draft", featuredState === "yes", Number(winnerBidNumber), Number(winningPrice));
    };

    const cancelEvent = (event: React.MouseEvent) => {
        event.preventDefault();
        history.goBack();
    }

    const refreshImages = async () => {
        await loadAuctionItem(history, Number(auctionId), Number(auctionItemId), setAuctionItem, setImages);
    };

    useEffect(() => {
        loadAuctionItem(history, Number(auctionId), Number(auctionItemId), setAuctionItem, setImages);
    }, []);

    return (
        <div>
            <Navbar history={history} />
            <div className="container mb-4">
                <div className="row justify-content-center">
                    <div id="image-upload-modal" className="modal" tabIndex={-1} role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <form onSubmit={fileUploadSubmit}>
                                    <div className="modal-header">Upload New Image</div>
                                    <div className="modal-body">
                                        <div id="upload-alert" className="alert alert-danger collapse">
                                            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /><span id="upload-alert-message"></span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="image-name">Title</label>
                                            <input id="image-name" name="image-name" type="text" className="form-control" onChange={onFileNameChanged} />
                                        </div>
                                        <div className="custom-file">
                                            <input id="customFile" type="file" className="custom-file-input" onChange={onFileChanged} placeholder={filePath} />
                                            <label id="custom-file-label" className="custom-file-label" htmlFor="customFile">Choose image</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="d-flex w-100">
                                            <div id="image-upload-buttons" className="ml-auto">
                                                <button className="btn btn-sm btn-outline-danger" onClick={e => dismissUpload(e)}>Cancel</button>
                                                <button className="btn btn-sm btn-outline-primary ml-4" type="submit">Upload</button>
                                            </div>
                                            <div id="image-upload-progress" className="ml-auto collapse">
                                                <div id="upload-loading" className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-4">
                        <form onSubmit={formSubmit}>
                            <div className="card">
                                <div className="card-header">Edit Auction Item</div>
                                <div className="card-body">
                                    <div id="form-alert" className="alert alert-success collapse">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /><span id="form-alert-message"></span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-title">Title</label>
                                        <input id="item-title" name="item-title" type="text" className="form-control" 
                                                value={title} onChange={e => onTitleChanged(e)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-content">Page Content</label>
                                        <ReactMde onChange={setContent}
                                            value={content}
                                            selectedTab={selectedTab}
                                            onTabChange={setSelectedTab}
                                            generateMarkdownPreview={(markdown) => Promise.resolve(mdConverter.makeHtml(markdown))} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-start-price">Starting Price</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="item-start-price" name="item-start-price" type="text" className="form-control"
                                                    value={startPrice} onChange={onStartPriceChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-price-increment">Bid Increment</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="item-bid-increment" name="item-bid-increment" type="text" className="form-control"
                                                    value={bidIncrement} onChange={onBidIncrementChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="item-reserve-price">Reserve Price</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="item-reserve-price" name="item-reserve-price" type="text" className="form-control"
                                                    value={reservePrice} onChange={onReservePriceChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="is-draft">Visibility</label><br/>
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label id="btn-live" className="btn btn-light">
                                                <input type="radio" name="item-state" id="item-state-live" onClick={onPublishedStateChanged} value="live" checked={publishState === "live"} /> Live
                                            </label>
                                            <label id="btn-draft" className="btn btn-light">
                                                <input type="radio" name="item-state" id="item-state-draft" onClick={onPublishedStateChanged} value="draft" checked={publishState === "draft"} /> Draft
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="is-featured">Featured</label><br/>
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label id="btn-featured" className="btn btn-light">
                                                <input type="radio" name="item-featured" id="item-featured-yes" onClick={onFeaturedStateChanged} value="yes" checked={featuredState === "yes"} /> Yes
                                            </label>
                                            <label id="btn-not-featured" className="btn btn-light">
                                                <input type="radio" name="item-featured" id="item-featured-no" onClick={onFeaturedStateChanged} value="no" checked={featuredState === "no"} /> No
                                            </label>
                                        </div>
                                    </div>
                                    {auctionType === "Live Auction" &&
                                    <hr className="mt-4 mb-4" />
                                    }
                                    {auctionType === "Live Auction" &&
                                    <div className="form-group">
                                        <label htmlFor="winning-bid-number">Winner Bid Number</label><br/>
                                        <input id="winning-bid-number" name="winning-bid-number" type="text" className="form-control" 
                                            value={winnerBidNumber} onChange={onWinnerBidNumberChanged} />
                                    </div>
                                    }
                                    {auctionType === "Live Auction" &&
                                    <div className="form-group">
                                        <label htmlFor="winning-bid-price">Winning Price</label><br/>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="winning-bid-price" name="winning-bid-price" type="text" className="form-control"
                                                    value={winningPrice} onChange={onWinningPriceChanged} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">.00</span>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="card-footer">
                                    <div className="d-flex w-100">
                                        <div className="ml-auto">
                                            <button className="btn btn-sm btn-outline-danger" onClick={cancelEvent}>Cancel</button>
                                            <button className="btn btn-sm btn-outline-primary ml-4" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-4 mt-4">
                        <div className="card">
                            <div className="card-header">Images</div>
                            <div className="card-body m-0 p-0">
                                <GalleryCollection images={images} auctionItemId={parseInt(auctionItemId)} refreshImages={refreshImages} />
                            </div>
                            <div className="card-footer">
                                <div className="d-flex w-100">
                                    <div className="ml-auto">
                                        <button type="button" className="btn btn-sm btn-outline-primary ml-4" data-toggle="modal" data-target="#image-upload-modal" onClick={e => showUpload(e)}>Upload New Image</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(EditAuctionItemPage);

